import { Component, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerTypes } from '../_common/enums/systemBranches.enums';
import { SnackBarService } from '../_common/snackBar.service';
import { Utilities } from '../_common/utils';
import { AuthService } from '../auth/data/auth.service';
import { RecruiterFilter, RecruitersService } from './data/recruiters.service';
import { GetRecruitersDto } from '../customer/data/dto/customer.dto';

@Component({
  selector: 'app-recruiters',
  templateUrl: './recruiters.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class RecruitersComponent {
    // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;


  permissions = Permissions.RECRUITERS_MANAGEMENT.recruiters;
  loading = false;
  resultsLength = 0;
  isSenderPartnerSelected: Boolean = false;
  displayedColumns: string[] = [
    'number',
    'firstName',
    'lastName',
    'code',
    'mobile',
    'commissionPercentage',
    'recruitedDate',
    'recruitedBy',
  ];
  customerTypes = CustomerTypes
  data = new MatTableDataSource<GetRecruitersDto>();
  customerStatusId : any = null;
  recruiterFilterTypeId: number = 1;

  constructor(
    private fb: UntypedFormBuilder,
    private recruiterService: RecruitersService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      recruiterCode: [null],
      recruiterFilterType: [1],
    });

    this.loadData();
  }

  loadData() {
    this.recruiterService.recruiters.subscribe(
      ({recruiters, appliedFilters }) => {
        this.loading = false;
        this.data.data = recruiters;
        this.data.paginator = this.paginator;
        this.resultsLength = recruiters.length;
      },
    );
  }

  onSubmit() {
    const payload: any = {};
    if (
      this.recruiterFilterTypeId == 1 &&
        this.form.get('recruiterCode')?.value
    ) {
      payload.recruiterCode = this.form.get('recruiterCode')?.value;
    }

    if (
      this.recruiterFilterTypeId == 2 &&
        this.form.get('recruiterCode')?.value
    ) {
      payload.recruiterName = this.form.get('recruiterCode')?.value;
    }
    

    
    this.recruiterService.searchRecruiters(payload);
  }

  onRecruiterFilterTypeChanged(event: any) {
    this.recruiterFilterTypeId = event.value;
  }


  
  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
