<div mat-dialog-title>
  <h1>{{ 'documentType' | translate }}</h1>
</div>
<form
  class="container-fluid flex-column p-0"
  id="form"
  name="form"
  [formGroup]="form"
  novalidate
  (submit)="onSubmit()"
>
  <div mat-dialog-content>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'name' | translate }}:</mat-label>
        <input matInput #name formControlName="name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'issueBy' | translate }}:</mat-label>
        <input matInput #issueBy formControlName="issueBy" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'code' | translate }}:</mat-label>
        <input matInput #code formControlName="code" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'minimumLength' | translate }}:</mat-label>
        <input matInput #minimumLength formControlName="minimumLength" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'maximumLength' | translate }}:</mat-label>
        <input matInput #maximumLength formControlName="maximumLength" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-lg-6">
        <mat-label>{{ 'regularExpression' | translate }}:</mat-label>
        <input
          matInput
          #regularExpression
          formControlName="regularExpression"
          (focusout)="onFocusout($event)"
        />
      </mat-form-field>
    </div>
  
    <div class="row">
      <div class="row mb-4 mt-0">
        <mat-label class="mb-4 text-bold">{{ 'requiredFields' | translate }}:</mat-label>
        <mat-checkbox
          matInput
          class="mx-1 col-md-3 col-3 col-sm-3"
          color="primary"
          formControlName="documentNumberIsRequired"
          checked
          value="true"
        >
          {{ 'documentNumber' | translate }}
        </mat-checkbox>

        <mat-checkbox
          matInput
          class="mx-1 col-md-3 col-3 col-sm-3"
          color="primary"
          formControlName="issueDateIsRequired"
          checked
          value="true"
        >
          {{ 'issueDate' | translate }}
        </mat-checkbox>

        <mat-checkbox
          matInput
          class="mx-1 col-md-3 col-3 col-sm-3"
          color="primary"
          formControlName="expiryDateIsRequired"
          checked
          value="true"
        >
          {{ 'expiryDate' | translate }}
        </mat-checkbox>
      </div>

      <div class="row mb-4 mt-0">
        <mat-checkbox
          matInput
          class="mx-1 col-md-3 col-3 col-sm-3"
          color="primary"
          formControlName="documentIssuerIsRequired"
          checked
          value="true"
        >
          {{ 'documentIssuer' | translate }}
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <mat-checkbox
        matInput
        class="mx-1"
        color="primary"
        formControlName="isPrimary"
        checked
        value="true"
      >
        {{ 'isPrimary' | translate }}
      </mat-checkbox>

      <!-- <div class="row mb-2 mt-0"> -->
        <mat-checkbox matInput class="mx-1" class="mx-1 col-md-3 col-3 col-sm-3" color="primary" formControlName="isActive"
          checked value="true">
          {{ 'isActive' | translate }}
        </mat-checkbox>
      <!-- </div> -->
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button type="submit" color="primary">
      {{ 'submit' | translate }}
    </button>
  </div>
</form>
