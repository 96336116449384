import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerFilter, CustomerService } from './data/customer.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { GetCustomerDto } from './data/dto/customer.dto';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerDocPopupComponent } from './customerDocPopup.component';
import { SnackBarService } from '../_common/snackBar.service';
import {conductorsPopupComponent} from "./conductorsPopup.component";
import {CustomerTypes} from "../_common/enums/systemBranches.enums";
import { CustomerModificationsPopup } from './customerModificationsPopup.component';
import { Utilities } from '../_common/utils';
import { SignupMandateComponent } from './signup-mandate.component';
import { SendRemittanceDeclarationComponent } from './sendRemittanceDeclaration.component';
import { OrmCustomerActionsComponent } from './ormcustomeractions.component';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class CustomersComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.customerManagement.customers;
  loading = false;
  resultsLength = 0;
  isSenderPartnerSelected: Boolean = false;
  displayedColumns: string[] = [
    'number',
    'customerId',
    'firstName',
    'middleName',
    'lastName',
    'mobile',
    'city',
    'customerCreatedDate',
    'customerOnboardDate',
    'customerStatus',
    'actions',
  ];
  customerTypes = CustomerTypes
  data = new MatTableDataSource<GetCustomerDto>();
  customerStatusId : any = null;
  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    public authService: AuthService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      mobilePhone: [null],
      blackListed: [null],
      isActive: [null],
      customerStatusId: [null],
      customerName: [null],
      documentNumber: [null],
    });

    this.loadData();
  }

  loadData() {
    this.customerService.customers.subscribe(
      ({ customers, appliedFilters }) => {
        this.loading = false;
        // if(this.blackListed){
        //   this.data.data = customers.filter(customer=>customer.localBlacklistGuid !==  null && customer.localBlacklistGuid !== "")
        // }else{
        this.data.data = customers;
        // }
        this.data.paginator = this.paginator;
        this.resultsLength = customers.length;
        this.form.controls['mobilePhone'].setValue(appliedFilters?.mobilePhone);
        // this.form.controls['blackListed'].setValue(appliedFilters?.blackListed);
        // this.form.controls['isActive'].setValue(appliedFilters?.inactive);
        // this.isSenderPartnerSelected = this.form.controls['senderPartner'].value;
      },
    );
  }

  onSubmit() {
    const { mobilePhone, blackListed, isActive, senderPartner, customerName, documentNumber, customerStatusId } = this.form.value;
    const filter: CustomerFilter = {};
    if (mobilePhone) filter.mobilePhone = mobilePhone;
    if (blackListed) filter.blackListed = blackListed;
    if (isActive) filter.inactive = isActive;
    if (senderPartner) filter.senderPartner = senderPartner;
    if (customerName) filter.customerName = customerName;
    if (documentNumber) filter.documentNumber = documentNumber;
    filter.customerStatusId = this.customerStatusId;
    this.customerService.searchCustomers(filter);
  }

  handleBlacklistAction(customerId: number, blacklist: boolean) {
    if (blacklist) {
      this.customerService.removeCustomerFromBlacklist(customerId).subscribe(
        (resp) => {
          this.snackBar.open(resp?.data?.message);
          this.loadData();
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
      return;
    }

    this.customerService.addCustomerToBlacklist(customerId).subscribe(
      (resp) => {
        this.snackBar.open(resp?.data.message);
        this.loadData();
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  openDocsDialog(customerId: number) {
    this.customerService.getCustomerDocs(customerId).subscribe((docs) => {
     const dialogRef = this.dialog.open(CustomerDocPopupComponent, {
        width: '1200px',
        disableClose: true,
        data: { docs, customerId },
      });
      
      dialogRef.afterClosed().subscribe(result => {
        const filter: CustomerFilter = {};
        filter.customerStatusId = this.customerStatusId;
        this.customerService.searchCustomers(filter);
      });
    }, ({ message }) => {
      this.snackBar.open(message);
    });

  }

  openConductorsDialog(customerId: number) {
    this.customerService.getConductorByCustomerId(customerId).subscribe((conductor) => {
    this.dialog.open(conductorsPopupComponent, {
        width: '1200px',
        disableClose: true,
        data: { conductors: conductor },
      });
    }, ({ message }) => {
      this.snackBar.open(message);
    }); 
  }

  onCustomerStatusChanged(event: any) {
   this.customerStatusId = event.value;
  }


  openActionsDialog(customerId: number, customerTypeId: number) {
    this.customerService.getCustomerDocs(customerId).subscribe((docs) => {
      const dialogRef = this.dialog.open(OrmCustomerActionsComponent, {
        width: '1400px',
        height: '600px',
        disableClose: true,
        data: { docs, customerId, customerTypeId },
      });
      dialogRef.afterClosed().subscribe((result) => { 
        this.onSubmit();
      });
    }, ({ message }) => {
      this.loadData();
    });
  }

  openCustomerModificationDialog(customerId: number) {
    this.customerService.getCustomerModifications(customerId).subscribe((customerModification) => {
      this.dialog.open(CustomerModificationsPopup, {
        width: '150vw',
        maxHeight: '90vh',
        data: { customerModification, customerId },
      });
    }, ({ message }) => {
      this.snackBar.open(message);
    });
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
}
}
