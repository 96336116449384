<app-index-container label="{{ 'customerDocumentTypes' | translate }}">
  <button
    headerActions
    *ngIf="
      authService.isUserAuthorized(
        permissions.ADD_CUSTOMER_DOCUMENT_TYPES_LOOKUP
      )
    "
    mat-raised-button
    color="primary"
    (click)="addDocumentTypeDialog()"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addDocumentType' | translate }}
  </button>
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.name"
        matTooltipPosition="after"
      >
        {{ row.name }}
      </td>
    </ng-container>

      <!-- issueBy Column -->
      <ng-container matColumnDef="issueBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'issueBy' | translate }}</th>
        <td
          mat-cell
          *matCellDef="let row"
          [matTooltip]="row.issueBy"
          matTooltipPosition="after"
        >
          {{ row.issueBy }}
        </td>
      </ng-container>


    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>{{ 'code' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>
    <!-- minimumLength Column -->
    <ng-container matColumnDef="minimumLength">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'minimumLength' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.minimumLength }}</td>
    </ng-container>

    <!-- maximumLength Column -->
    <ng-container matColumnDef="maximumLength">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'maximumLength' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.maximumLength }}</td>
    </ng-container>
    <!-- regularExpression Column -->
    <ng-container matColumnDef="regularExpression">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'regularExpression' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.regularExpression"
        matTooltipPosition="after"
      >
        {{ row.regularExpression }}
      </td>
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ checkDocumentType(row.isPrimary) | translate }}
      </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_CUSTOMER_DOCUMENT_TYPES_LOOKUP,
                permissions.VIEW_CUSTOMER_DOCUMENT_TYPES_LOOKUP
              ])
            "
            color="primary"
            (click)="addDocumentTypeDialog(row.id)"
          >
            <mat-icon>edit</mat-icon>{{ 'edit' | translate }}</a
          >
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
