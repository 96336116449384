<table table mat-table [dataSource]="data" class="table">
  <!-- Number Column -->
  <ng-container matColumnDef="number">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
  </ng-container>

  <!-- Customer id Column -->
  <ng-container matColumnDef="customerId">
    <th mat-header-cell *matHeaderCellDef>{{ 'customerId' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ row.id }}</td>
  </ng-container>

  
  <!-- First Name Column -->
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>{{ 'firstName' | translate }}</th>
    <td [style.color]="row.localBlacklistGuid ? 'red' : 'null'" mat-cell *matCellDef="let row">
      {{ row.firstName }}
    </td>
  </ng-container>
  <!-- Middle Name Column -->
  <ng-container matColumnDef="middleName">
    <th mat-header-cell *matHeaderCellDef>{{ 'middleName' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ row.middleName }}</td>
  </ng-container>
  <!-- Last Name Column -->
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>{{ 'lastName' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
  </ng-container>
  <!-- Mobile Column -->
  <ng-container matColumnDef="mobile">
    <th mat-header-cell *matHeaderCellDef>{{ 'mobile' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ row.mobilePhone }}</td>
  </ng-container>
  <!-- City Column -->
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef>{{ 'city' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ row.city }}</td>
  </ng-container>

  <!-- Customer registeration date Column -->
  <ng-container matColumnDef="customerCreatedDate">
    <th mat-header-cell *matHeaderCellDef>{{ 'createdDate' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.createdDate, 'datetime')}}</td>
  </ng-container>

  <!-- Customer onboard date Column -->
  <ng-container matColumnDef="customerOnboardDate">
    <th mat-header-cell *matHeaderCellDef>{{ 'onboardDate' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.onboardDate, 'datetime') }}</td>
  </ng-container>

  <ng-container matColumnDef="customerStatus">
    <th mat-header-cell *matHeaderCellDef>Customer Status</th>
    <td mat-cell *matCellDef="let row">{{ row.customerStatus }}</td>
  </ng-container>
  <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
    <td mat-cell *matCellDef="let row">
      <div class="action-button-menu">
        <a mat-menu-item class="primary" (click)="openActionsDialog(row.id, row.customerTypeId)">
          <mat-icon>visibility</mat-icon>
          {{ 'view actions' | translate }}
        </a>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>