import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, throwIfEmpty } from 'rxjs/operators';
import { HttpService } from '../../_common/data/http.service';
import { CRUDResponseDto, LookupDto } from '../../_common/data/dto/api.dto';
import {
  UpsertCustomerDto,
  CustomerDocTypesResponseDto,
  SearchCustomerResponseDto,
  GetCustomerDto,
  ReceiverDto, upsertConductor, GetConductor, GetCustomerTypesDto, CustomerDto,
  SearchCustomerBlacklistResponseDto,
  GetCustomerBlacklistDto,
  GetCustomerBlacklistSantionedAuditLogsDto,
} from './dto/customer.dto';
import {CreateConductorDocumentDto, CustomerDocumentDto} from './dto/customerDocument.dto';
import { AccountVerificationDto } from './dto/accountVerificationDto';
import { TableTypes } from 'src/app/_common/enums/tableTypes.enums';
import { CustomerStatusResponseDto } from './dto/customerStatusResponse.dto';
import { CustomerBlacklist } from '../addBlacklist.component';
import { AuthService } from 'src/app/auth/data/auth.service';

export type CustomerFilter = {
  mobilePhone?: string;
  blackListed?: boolean;
  inactive?: boolean;
  senderPartner?: boolean;
  customerName?: string;
  documentNumber?: string;
  customerStatusId?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  limit?: number | undefined;
};

export type CustomerBlacklistFilter = {
  mobilePhone?: string;
  customerName?: string;
  limit?: number | undefined;
};

export type CustomerBlacklistSantionedAuditLogsFilter = {
  startDate?: Date | null;
  endDate?: Date | null;
  limit?: number | undefined;
};


type GetCustomers = {
  appliedFilters: CustomerFilter | null;
  customers: GetCustomerDto[];
};

type GetCustomersBlacklist = {
  appliedFilters: CustomerBlacklistFilter | null;
  customersBlacklist: GetCustomerBlacklistDto[];
};

type GetCustomersBlacklistSantinAuditLogs = {
  appliedFilters: CustomerBlacklistSantionedAuditLogsFilter | null;
  customersBlacklistSanctionAuditLogs: GetCustomerBlacklistSantionedAuditLogsDto[];
};

@Injectable()
export class CustomerService {
  private tablePendingChanges: string = '';
  private _customer = new BehaviorSubject<GetCustomers>({
    appliedFilters: null,
    customers: [],
  });
  private _sanctionImportList = new BehaviorSubject<any>({
    sanctionImportList: [],
  });
  private _sanctionedList = new BehaviorSubject<any>({
    appliedFilters: null,
    sanctionedList: [],
  });
  private _customerBlacklist = new BehaviorSubject<GetCustomersBlacklist>({
    appliedFilters: null,
    customersBlacklist: [],
  });
  private _customerBlacklistSanctionedAuditLogs = new BehaviorSubject<GetCustomersBlacklistSantinAuditLogs>({
    appliedFilters: null,
    customersBlacklistSanctionAuditLogs: [],
  });
  private customersObservable?: Observable<GetCustomers>;
  private sanctionImportListObservable?: Observable<any>;
  private sanctionedListObservable?: Observable<any>;
  private customersBlacklistObservable?: Observable<GetCustomersBlacklist>;
  private customersBlacklistSanctionedObservable?: Observable<GetCustomersBlacklistSantinAuditLogs>;
  constructor(private httpService: HttpService, private authService: AuthService) {}

  get customers() {
    if (!this.customersObservable)
      this.customersObservable = this._customer.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.CUSTOMERS) {
            this.searchCustomers(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.customersObservable;
  }

  get sanctionImportList() {
    if (!this.sanctionImportListObservable)
      this.sanctionImportListObservable = this._sanctionImportList.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.CUSTOMERS) {
            this.getSanctionImportList(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.sanctionImportListObservable;
  }

  get sanctionedList() {
    if (!this.sanctionedListObservable)
      this.sanctionedListObservable = this._sanctionedList.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.CUSTOMERS) {
            this.getSanctionedList(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.sanctionedListObservable;
  }

  get customersBlacklist() {
    if (!this.customersBlacklistObservable)
      this.customersBlacklistObservable = this._customerBlacklist.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.CUSTOMERS) {
            this.searchCustomersBlacklist(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.customersBlacklistObservable;
  }

  get customersBlacklistSacntinedAuditLogs() {
    if (!this.customersBlacklistSanctionedObservable)
      this.customersBlacklistSanctionedObservable = this._customerBlacklistSanctionedAuditLogs.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.CUSTOMERS) {
            this.searchCustomersBlacklistSanctionAuditLogs(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.customersBlacklistSanctionedObservable;
  }

  searchCustomer(
    filter: string | null,
  ): Observable<SearchCustomerResponseDto[]> {
    return this.httpService.get<SearchCustomerResponseDto[]>(
      'customer/search',
      filter ? { q: filter } : null,
    );
  }

  updateCustomerStatus(customerId: number, statusId: number, comment: string): Observable<CustomerStatusResponseDto>{
    return this.httpService.post<CustomerStatusResponseDto>(
      'customer/updatecustomerstatus',
      {customerId:customerId, statusId: statusId, comment: comment }
    );
  }

  searchCustomers(filter: CustomerFilter | null) {
    this.httpService
      .get<SearchCustomerResponseDto[]>('customer/searchAll', filter)
      .subscribe((customers) => {
        this.tablePendingChanges = '';
        this._customer.next({
          customers: customers,
          appliedFilters: filter,
        });
      });
  }

  searchCustomersBlacklist(filter: CustomerBlacklistFilter | null) {
    this.httpService
      .get<GetCustomerBlacklistDto[]>('customer/searchblacklist', filter)
      .subscribe((customers) => {
        this.tablePendingChanges = '';
        this._customerBlacklist.next({
          customersBlacklist: customers,
          appliedFilters: filter,
        });
      });
  }

  searchCustomersBlacklistSanctionAuditLogs(filter: CustomerBlacklistSantionedAuditLogsFilter | null) {
    this.httpService
      .get<GetCustomerBlacklistSantionedAuditLogsDto[]>('customer/searchblacklistsanctionedauditlogs', filter)
      .subscribe((customers) => {
        this.tablePendingChanges = '';
        this._customerBlacklistSanctionedAuditLogs.next({
          customersBlacklistSanctionAuditLogs: customers,
          appliedFilters: filter,
        });
      });
  }

  getCustomers(id?: number): Observable<GetCustomerDto[]> {
    return this.httpService.get<SearchCustomerResponseDto[]>(
      'customer/list',
      id ? { id } : null,
    );
  }

  getCustomerGenders(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('customer/gender/list');
  }

  getCustomerOccupations(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('customer/occupation/list');
  }

  getCustomerSourceOfIncome(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('customer/sourceOfIncome/list');
  }

  validateCustomerInfo(
    mobilePhone: string,
    remittanceSubTypeId: number,
    bankCode?: string,
  ): Observable<AccountVerificationDto> {
    return this.httpService.post<AccountVerificationDto>(
      'customer/info/validate',
      { mobilePhone, remittanceSubTypeId, bankCode },
    );
  }

  getCustomerDocs(customerId: number): Observable<CustomerDocumentDto[]> {
    return this.httpService
      .get<CustomerDocumentDto[]>('customer/document/list', { customerId })
      .pipe(map((docs) => docs.map(this.prepareDocument)));
  }

  getSenderReceivers(customerId: number): Observable<ReceiverDto[]> {
    return this.httpService.get<ReceiverDto[]>('remittance/sender/receivers', {
      customerId,
    });
  }

  getCustomerWithDocs(id: number): Observable<GetCustomerDto> {
    return forkJoin([
      this.getCustomers(id).pipe(throwIfEmpty()),
      this.getCustomerDocs(id),
      this.getSenderReceivers(id),
    ]).pipe(
      map(([customers, docs, receivers]) => ({
        ...customers[0],
        docs,
        receivers,
      })),
    );
  }

  upsertCustomer(customer: UpsertCustomerDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('customer/upsert', customer);
  }

  updateCustomer(customer: UpsertCustomerDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('customer/update', customer)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.CUSTOMERS;
          return resp;
        }),
      );
  }

  getCustomerDocTypes(): Observable<CustomerDocTypesResponseDto[]> {
    return this.httpService.get<CustomerDocTypesResponseDto[]>(
      'customer/doctype/list',
    );
  }

  upsertCustomerDocument(
    doc: CustomerDocumentDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      `customer/document/${doc.id ? 'update' : 'create'}`,
      doc,
    );
  }

  uploadDocumentAttachment(
    docId: number,
    file: File[],
    isThisConductorDocument: number = 0,
  ): Observable<CRUDResponseDto> {
    const reqData = new FormData();
    reqData.append('isThisConductorDocument', isThisConductorDocument?.toString());
    reqData.append('docId', docId.toString());
    if (Array.isArray(file)) {
      file.forEach((file) => {
        reqData.append('file', file);
      });
    } else {
      reqData.append('file', file);
    }
    return this.httpService.post<CRUDResponseDto>(
      `customer/document/upload`,
      reqData,
    );
  }

  uploadAuthorizedSignatureCustomerDocument(
    customerId: number,
    file: File[]
  ): Observable<any> {
    const reqData = new FormData();
    reqData.append('customerId', customerId.toString());
    if (Array.isArray(file)) {
      file.forEach((file) => {
        reqData.append('file', file);
      });
    } else {
      reqData.append('file', file);
    }
    return this.httpService.post<any>(
      `customer/document/uploadauthorizedsignature`,
      reqData,
    );
  }

  upsertConductorDocument(
    doc: CreateConductorDocumentDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      `customer/document/${doc.id ? 'updateconductordocument' : 'createconductordocument'}`,
      doc,
    );
  }

  prepareDocument(d: CustomerDocumentDto): CustomerDocumentDto {
    const { number, issueDate, expiryDate, issuedBy } = d.documentDetails;
    const isActive = d.isActive;
    return { ...d, number, issueDate, expiryDate, issuedBy, isActive };
  }

  getCustomerDocument(path: string): Observable<Blob> {
    return this.httpService.getAttachment('customer/attachment', { path });
  }

  removeCustomerFromBlacklist(customerId: number): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>(`customer/blacklist/local/delete`, {
        id: customerId,
      })
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.CUSTOMERS;
          return resp;
        }),
      );
  }

  addCustomerToBlacklist(customerId: number): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>(`customer/blacklist/local/add`, { id: customerId })
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.CUSTOMERS;
          return resp;
        }),
      );
  }

  addCustomerBlacklist(customerBlacklist: CustomerBlacklist): Observable<any> {
    return this.httpService.post<CustomerBlacklist>(`customer/addcustomerblacklist`, customerBlacklist).pipe(
      map((resp) => {
        return resp;
      }),
    );;
  }

  importExcelData(file: File): Observable<any> {
    const reqData = new FormData();
    reqData.append('file', file);
    return this.httpService.post<CRUDResponseDto>(
      'customer/importsanctionscreen',
      reqData,
    );
   
  }

  updateCustomerBlacklist(customerId: number, customerBlacklist: CustomerBlacklist): Observable<any> {
    const body = {
      customerId: customerId,
      ...customerBlacklist,
    };
  
    return this.httpService.post<CustomerBlacklist>('customer/updatecustomerblacklist', body).pipe(
      map((resp) => resp)
    );
  }
  

  searchCustomerBlacklisted(customerId: number): Observable<any> {
    return this.httpService.get<any>(`customer/searchblacklistcustomer?id=${customerId}`);
  }

  
  getSanctionImportList(name: string){
    this.httpService
    .get<any[]>(`customer/sanctionscreendsdata?name=${name}`)
    .subscribe((sanctionImportList) => {
      this.tablePendingChanges = '';
      this._sanctionImportList.next({
        sanctionImportList: sanctionImportList,
        appliedFilters: name,
      });
    });
  }

  getSanctionedList(id: number | null){
    this.httpService
    .get<any[]>(`customer/sanctionedcheckedlist?id=${id}`)
    .subscribe((sanctionedList) => {
      this.tablePendingChanges = '';
      this._sanctionedList.next({
        sanctionedList: sanctionedList,
        appliedFilters: id,
      });
    });
  }
  

  upsertConductor(conductor: upsertConductor): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('customer/upsertconductor', conductor);
  }

  getConductorByCustomerId(customerId: number): Observable<GetConductor[]> {
    return this.httpService.get<GetConductor[]>('customer/getconductorbycustomerid',{customerId}).pipe(
      map((resp) =>  resp));
  }

  getConductorById(conductorId: number): Observable<GetConductor[]> {
    return this.httpService.get<GetConductor[]>('customer/getconductorbyid',{ conductorId }).pipe(
      map((resp) =>  resp));
  }

  getCustomerTypes(): Observable<GetCustomerTypesDto[]> {
    return this.httpService.get<GetCustomerTypesDto[]>('customer/customertypes/list');
  }

  checkCustomersWithSameMobile(filter: any) {
    return this.httpService.post<any>(
      'customer/checkcustomerswithsamemobile',
      filter
    );
  }

  sendSmsOnMobileVerification(mobilePhone: string) {
    return this.httpService.post<any>(
      'customer/sendsms',
      mobilePhone
    );
  }

  deactivateDuplicateCustomers(duplicateCustomers: CustomerDto[]) {
    return this.httpService.post<any>(
      'customer/deactiveduplicatecustomers',
      duplicateCustomers
    );
  }

  getCustomerModifications(customerId: number): Observable<any[]> {
    return this.httpService
      .get<any[]>('customer/getcustomermodifications', { customerId });
  }

  getSanctionScreeningResultFile(path: string): Observable<Blob> {
    return this.httpService.getAttachment('customer/sanctionscreeningresultfile', { path });
  }

}
