<app-index-container label="{{ 'Finsurv Transactions' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row">
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
                <input matInput #remittanceCode formControlName="remittanceCode" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'Report Reference' | translate }}:</mat-label>
                <input matInput #ReportReference formControlName="ReportReference" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" [value]="fromDate.value" />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'End Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" [value]="toDate.value" />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3">
                <mat-label>{{ 'Flow' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="flow">
                    <mat-radio-button class="material-radio" checked value="Out" style="margin-left: 5px">{{ 'OUT' |
                        translate
                        }}</mat-radio-button>

                        <mat-radio-button class="material-radio" checked value="In" style="margin-left: 5px">{{ 'In' |
                            translate
                            }}</mat-radio-button>

                            <mat-radio-button class="material-radio" value="" style="margin-left: 10px">
                                {{ 'All' | translate }}
                            </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-md-6 col-lg-4 mt-2">
                <mat-label>{{ 'BOP Category' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="bopCategory">
                    <div class="radio-group-row d-flex flex-wrap">
                        <mat-radio-button class="material-radio" value="401" style="margin: 5px;">
                            {{ '401 Gifts' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="416" style="margin: 5px;">
                            {{ '416 Migrant Worker Remittances' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="417" style="margin: 5px;">
                            {{ '417 Foreign national contract Worker Remittances' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="400" style="margin: 5px;">
                            {{ '400 Reversals' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="" style="margin: 5px; margin-top: 5px;">
                            {{ 'All' | translate }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>


        <div class="d-flex justify-content-end">
            <button (click)="exportToExcel()" mat-raised-button type="button" color="accent" style="margin-right: 10px;">
                <i class="fa-solid fa-file-export"></i>
                {{ 'Export' | translate }}
            </button>
            <button mat-raised-button type="submit" color="primary">
                <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
            </button>
        </div>
    </form>
    <table table mat-table [dataSource]="remittances" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="#">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
    
        <!-- Remittance Code Column -->
        <ng-container matColumnDef="remittanceCode">
            <th mat-header-cell *matHeaderCellDef>{{ 'Remittance Code' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <span>
                    {{ row.remittanceCode }}
                </span>
            </td>
        </ng-container>
    
        <!-- Sender Name Column -->
        <ng-container matColumnDef="senderName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Sender Name' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.senderFullName">{{ row.senderFullName }}</td>
        </ng-container>
    
        <!-- Sender Mobile Column -->
        <ng-container matColumnDef="senderMobile">
            <th mat-header-cell *matHeaderCellDef>{{ 'Sender Mobile' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.senderMobile }}</td>
        </ng-container>
    
        <!-- Receiver Name Column -->
        <ng-container matColumnDef="receiverName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Receiver Name' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.receiverFullName">{{ row.receiverFullName }}</td>
        </ng-container>
    
        <!-- Receiver Mobile Column -->
        <ng-container matColumnDef="receiverMobile">
            <th mat-header-cell *matHeaderCellDef>{{ 'Receiver Mobile' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.receiverMobile }}</td>
        </ng-container>
    
        <!-- Flow Column -->
        <ng-container matColumnDef="flow">
            <th mat-header-cell *matHeaderCellDef>{{ 'Flow' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.flow }}</td>
        </ng-container>
    
        <!-- BoP Category Column -->
        <ng-container matColumnDef="bopCategory">
            <th mat-header-cell *matHeaderCellDef>{{ 'BoP Category' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.bopCategory }}</td>
        </ng-container>
    
        <!-- Branch Code Column -->
        <ng-container matColumnDef="branchCode">
            <th mat-header-cell *matHeaderCellDef>{{ 'Branch Code' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.senderBranch }}</td>
        </ng-container>
    
        <!-- Value remittanceStatus Column -->
        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef>{{ 'Remittance Status' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.remittanceStatus">{{ row.remittanceStatus }}</td>
        </ng-container>

        <!-- Value Date Column -->
        <ng-container matColumnDef="remittanceStatus">
            <th mat-header-cell *matHeaderCellDef>{{ 'Date' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.pDate }}</td>
        </ng-container>
    
        <!-- Rand Amount Column -->
        <ng-container matColumnDef="randAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'Rand Amount' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.sendingLocalAmount | number:'1.0-5' }}</td>
        </ng-container>
    
        <!-- Foreign Amount Column -->
        <ng-container matColumnDef="foreignAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'Foreign Amount' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.receivingLocalAmount">{{ row.receivingLocalAmount | number:'1.0-5' }}</td>
        </ng-container>
    
        <!-- Foreign Currency Code Column -->
        <ng-container matColumnDef="foreignCurrencyCode">
            <th mat-header-cell *matHeaderCellDef>{{ 'Foreign Currency Code' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.receivingCurrencyCode }}</td>
        </ng-container>
    
        <!-- Batch number Column -->
        <ng-container matColumnDef="trnReference">
            <th mat-header-cell *matHeaderCellDef>{{ 'Batch No' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.trnReference">{{ row.trnReference }}</td>
        </ng-container>

        <!-- Transaction Reference Column -->
        <ng-container matColumnDef="transactionReference">
            <th mat-header-cell *matHeaderCellDef>{{ 'Transaction Ref No' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.transactionReference">{{ row.transactionReference }}</td>
        </ng-container>

        <!-- Transaction Type Column -->
        <ng-container matColumnDef="finsurvTransactionType">
            <th mat-header-cell *matHeaderCellDef>{{ 'Transaction Type' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.finsurvTransactionType">{{ row.finsurvTransactionType }}</td>
        </ng-container>
    
        <!-- Transaction Status Column -->
        <ng-container matColumnDef="transactionstatus">
            <th mat-header-cell *matHeaderCellDef>{{ 'Transaction Status' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.tranState">{{ row.tranState }}</td>
        </ng-container>
    
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ 'Actions' | translate }}</th>
            <td mat-cell *matCellDef="let row">

                <div class="action-menu">
                    <a [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </a>
                
                    <mat-menu #menu="matMenu" class="action-menu-item">
                        <a mat-menu-item class="cancel-action" *ngIf="row.userCanCancelFinsurvTransactions" (click)="cancelTransaction(row)">
                            <mat-icon class="action-icon">history</mat-icon>{{ 'Cancel Transaction' | translate }}
                        </a>
                        <a mat-menu-item class="reverse-action" *ngIf="row.userCanReverseFinsurvTransactions" (click)="reverseTransaction(row)">
                            <mat-icon class="action-icon">history</mat-icon>{{ 'Reverse Transaction' | translate }}
                        </a>
                        <a mat-menu-item class="replace-action" *ngIf="row.userCanReplaceFinsurvTransactions" (click)="replaceTransaction(row)">
                            <mat-icon class="action-icon">history</mat-icon>{{ 'Replace Transaction' | translate }}
                        </a>

                        <a mat-menu-item class="replace-action" *ngIf="row.userCanResubmitFinsurvTransactions" (click)="reSubmitTransaction(row)">
                            <mat-icon class="action-icon">history</mat-icon>{{ 'Resubmit Transaction' | translate }}
                        </a>

                        <a mat-menu-item (click)="onRemittanceClick(row.remittanceCode)">
                            <mat-icon>history</mat-icon>{{ 'View Remittance' | translate }}
                        </a>

                        <a mat-menu-item (click)="viewChangeLog(row.changeLog)">
                            <mat-icon>history</mat-icon>{{ 'View Change Log' | translate }}
                        </a>

                        <a mat-menu-item (click)="openFinsurvModificationDialog(row.remittanceCode)">
                            <mat-icon>history</mat-icon>{{ 'View modification' | translate }}
                        </a>
                    </mat-menu>
                </div>


            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                {{ 'noRecordsAvailable' | translate }}
            </td>
        </tr>
    </table>


    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>