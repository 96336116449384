import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RecruitersRoutesModule } from "./recruiters.routes.module";
import { MatProgressSpinnerModule, MatSpinner } from "@angular/material/progress-spinner";
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { FormsModule, ReactiveFormsModule, ɵInternalFormsSharedModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RecruitersComponent } from "./recruiters.component";
import { RecruitersService } from "./data/recruiters.service";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ContainersModule } from "../_common/components/containers/containers.module";


@NgModule({
  declarations: [RecruitersComponent],
  imports: [
     CommonModule,
     RecruitersRoutesModule,
     MatIconModule,
     MatProgressSpinnerModule,
     MatCardModule,
     MatDatepickerModule,
     MatInputModule,
     MatNativeDateModule,
     FormsModule,
     MatSelectModule,  
     MatOptionModule,
     MatButtonModule,
     TranslateModule,
     MatPaginatorModule,
     MatTableModule,
     MatTooltipModule,
     MatRadioModule,
     ReactiveFormsModule,
     BrowserAnimationsModule,
     ContainersModule,
     MatButtonModule
  ],
  exports: [],
  providers: [RecruitersComponent, RecruitersService],
})
export class RecruitersModule {}
