import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SnackBarService } from "../_common/snackBar.service";
import { ReferalService } from "./data/referl.service";
import { MatTableDataSource } from "@angular/material/table";
import { ormRecuirterCustomersComponent } from "./ormRecruiterCustomers.component";
import { OrmRecruiterRemittancesComponent } from "./ormRecruiterRemittance.component";
import { OrmRecruiterBalanceCommissionComponent } from "./ormRecruiterBalanceCommission.component";
import { OrmRecruiterCommissionComponent } from "./ormRecruiterCommission.component";
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-referal-dashboard',
  templateUrl: './referalDashboard.component.html',
  styleUrls: ['../remittance/orm.component.css'],
})
export class ReferalDashboardComponent implements OnInit, OnDestroy {
  startDate: Date | null = null
  endDate: Date | null = null;
  intervalId: any;
  referralName: string = ''
  referralCode: string = ''
  commissionPercentage: number = 0
  resultsLength = 0;

   // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    'number',
    'firstName',
    'lastName',
    'code',
    'commissionPercentage'

  ];

  referralInfo = new MatTableDataSource<any>();

  customersDataSource = new MatTableDataSource<CustomerSummary>([
      { status: 'Total Customers', count: 0, isLoading: false, recruiterCode: "" },
  ]);

  remittancesDataSource = new MatTableDataSource<CustomerSummary>([
    { status: 'Total Remittances', count: 0, isLoading: false, recruiterCode: "" },
  ]);

  blanceCommissionDataSource = new MatTableDataSource<CustomerSummary>([
    { status: 'Unprocessed Commission', count: 0, isLoading: false, recruiterCode: "" },
  ]);

  commissionDataSource = new MatTableDataSource<CustomerSummary>([
    { status: 'Processed Commission', count: 0, isLoading: false, recruiterCode: "" },
  ]);

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private referalService: ReferalService,
    private snackBar: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());
    this.endDate = new Date();
    this.endDate.setDate(this.startDate.getDate());

    this.getReferalCustomerCount()
    this.getReferalRemittanceCount() 
    this.getReferalBlanaceCommision() 
    this.getReferalCommision() 

    this.referalService.getReferalInformation();

    this.referalService.referalInformation.subscribe(
      ({ referalInformation, appliedFilters }) => {
        this.referralInfo.data = [referalInformation];
        console.log(this.referralInfo.data)
        this.referralInfo.paginator = this.paginator;
        this.resultsLength = referalInformation.length;      
      }

    );


    this.intervalId = setInterval(() => {
      if (this.startDate && this.endDate) {
        this.getReferalCustomerCount() 
        this.getReferalRemittanceCount()
        this.getReferalBlanaceCommision()
        this.getReferalCommision()
      }
    }, 60000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  onSubmit() {
    this.getReferalCustomerCount()
    this.getReferalRemittanceCount()
    this.getReferalBlanaceCommision() 
    this.getReferalCommision()
  }



  getReferalCustomerCount() {
    if (this.startDate && this.endDate) {
      this.customersDataSource.data[0].isLoading = true;
      this.referalService.getReferalCustomerCount(this.startDate, this.endDate)
      .subscribe({
        next: (response: any) => {
          this.customersDataSource.data = [
            { status: 'Total Customers', count: response?.count, isLoading: false, recruiterCode: response.recruiterCode },
          ];
        },
        error: (error) => {
          this.customersDataSource.data[0].isLoading = false;
          console.error('Error fetching total customers:', error);
        },
      });
  
    } else {
      this.snackbar.open('please select start date and end date');
    }
  }

  ormReferalCustomerCountClick(recruiterCode?: string, limit?: number): void {
    if (recruiterCode) {
      const dialogRef = this.dialog.open(ormRecuirterCustomersComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { ormRecuirterCode: recruiterCode, startDate: this.startDate, endDate: this.endDate, limit: limit},
        panelClass: 'scrollable-dialog'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.onSubmit()
      });
    }
  }

  getReferalRemittanceCount() {
    if (this.startDate && this.endDate) {
      this.remittancesDataSource.data[0].isLoading = true;
      this.referalService.getReferalRemittanceCount(this.startDate, this.endDate)
      .subscribe({
        next: (response: any) => {
          this.remittancesDataSource.data = [
            { status: 'Total Remittances', count: response?.count, isLoading: false, recruiterCode: response.recruiterCode },
          ];
        },
        error: (error) => {
          this.remittancesDataSource.data[0].isLoading = false;
          console.error('Error fetching total remittances:', error);
        },
      });
  
    } else {
      this.snackbar.open('please select start date and end date');
    }
  }

  ormReferalRemittanceCountClick(recruiterCode?: string, limit?: number): void {
    if (recruiterCode) {
      const dialogRef = this.dialog.open(OrmRecruiterRemittancesComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { recruiterCode: recruiterCode, startDate: this.startDate, endDate: this.endDate, limit: limit},
        panelClass: 'scrollable-dialog'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.onSubmit()
      });
    }
  }

  getReferalBlanaceCommision() {
    if (this.startDate && this.endDate) {
      this.blanceCommissionDataSource.data[0].isLoading = true;
      this.referalService.getReferalBlanaceCommision(this.startDate, this.endDate)
      .subscribe({
        next: (response: any) => {
          this.blanceCommissionDataSource.data = [
            { status: 'Unprocessed Commission', count: response?.count, isLoading: false, recruiterCode: response.recruiterCode },
          ];
        },
        error: (error) => {
          this.blanceCommissionDataSource.data[0].isLoading = false;
          console.error('Error fetching Unprocessed commission:', error);
        },
      });
  
    } else {
      this.snackbar.open('please select start date and end date');
    }
  }

  ormReferalBalanceCommissionCountClick(recruiterCode?: string, limit?: number): void {
    console.log(recruiterCode)
    if (recruiterCode) {
      const dialogRef = this.dialog.open(OrmRecruiterBalanceCommissionComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { recruiterCode: recruiterCode, startDate: this.startDate, endDate: this.endDate, limit: limit},
        panelClass: 'scrollable-dialog'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.onSubmit()
      });
    }
  }

  ormReferalCommissionCountClick(recruiterCode?: string, limit?: number): void {
    console.log(recruiterCode)
    if (recruiterCode) {
      const dialogRef = this.dialog.open(OrmRecruiterCommissionComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { recruiterCode: recruiterCode, startDate: this.startDate, endDate: this.endDate, limit: limit},
        panelClass: 'scrollable-dialog'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.onSubmit()
      });
    }
  }

  getReferalCommision() {
    if (this.startDate && this.endDate) {
      this.commissionDataSource.data[0].isLoading = true;
      this.referalService.getReferalCommision(this.startDate, this.endDate)
      .subscribe({
        next: (response: any) => {
          this.commissionDataSource.data = [
            { status: 'Processed Commission', count: response?.count, isLoading: false, recruiterCode: response.recruiterCode },
          ];
        },
        error: (error) => {
          this.commissionDataSource.data[0].isLoading = false;
          console.error('Error fetching Processed Commission:', error);
        },
      });
  
    } else {
      this.snackbar.open('please select start date and end date');
    }
  }

  copyReferralCode(): void {
    if (!this.referralCode) return;
    navigator.clipboard.writeText(this.referralCode)
        .then(() => {
            this.snackBar.open('Referral code copied to clipboard!')
        })
        .catch(err => {
            this.snackBar.open('Failed to copy code')
            console.error('Failed to copy text: ', err);
        });
  }

}

interface CustomerSummary {
  status: string;
  count: number;
  isLoading?: boolean;
  recruiterCode?: string
}