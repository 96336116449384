import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-images',
  templateUrl: './documentImages.component.html',
  styleUrls: ['./domentImages.component.css']
})
export class DocumentImagesComponent {
  images: { src: string; alt: string; title: string; isFaceIdentical?: string; faceConfidence?: string }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.images = [
      { 
        src: data.imageUrl, 
        alt: 'Selfie Photo', 
        title: 'Selfie Photo',
      },
      { 
        src: data.documentImages, 
        alt: 'Document Image', 
        title: 'Document Image' 
      }
    ];

    if (this.data.documentTypeId !== 1) {
      this.images.push({
        src: data.ormIdVerificationResultImage,
        alt: data.ormIdVerificationResultImage ? 'Home Affairs Result' : 'Home Affairs Result Not Found', 
        title: data.ormIdVerificationResultImage ? 'Home Affairs Result' : 'Home Affairs Result Not Found',
        isFaceIdentical: data.isFaceIdentical,
        faceConfidence: data.faceConfidence
      });
    }
  }
}
