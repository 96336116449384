<app-index-container label="{{ 'Recruiters' | translate }}">
    <form
      form
      class="container-fluid flex-column p-0"
      name="form"
      [formGroup]="form"
      novalidate
      (submit)="onSubmit()"
    >

    <div class="row mt-4 mb-2">
      <div class="col-4 mx-1">
        <label for="Customer Status">Recruiter Filter Type</label>
        <mat-radio-group formControlName="recruiterFilterType" class="col-3 mb-2 mt-3" (change)="onRecruiterFilterTypeChanged($event)">
          <mat-radio-button [value]="1" checked style="margin-left: 8px;">
            Recruiter Code
          </mat-radio-button>
    
          <mat-radio-button [value]="2" style="margin-left: 8px;">
            Recruiter Name
          </mat-radio-button>
    
        </mat-radio-group>
      </div>
    </div>

      <div class="row">
        <mat-form-field appearance="outline" class="col">
          <mat-label>{{ 'Search recruiter' | translate }}:</mat-label>
          <input matInput #recruiterCode formControlName="recruiterCode" />
        </mat-form-field>
      </div>
      
        <div class="col">
          <div class="d-flex justify-content-end">
            <button
              mat-raised-button
              type="submit"
              color="primary"
            >
              <i class="fa-solid fa-magnifying-glass"></i>
              {{ 'search' | translate }}
            </button>
          </div>
        </div>
    </form>
    <table table mat-table [dataSource]="data" class="table">
      <!-- Number Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      </ng-container>
      <!-- First Name Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>{{ 'firstName' | translate }}</th>
        <td
          [style.color]="row.localBlacklistGuid ? 'red' : 'null'"
          mat-cell
          *matCellDef="let row"
        >
          {{ row.firstName }}
        </td>
      </ng-container>
  
      <!-- Last Name Column -->
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>{{ 'lastName' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
      </ng-container>
      <!-- Mobile Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef>{{ 'mobile' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.mobile }}</td>
      </ng-container>
       
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>{{ 'code' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.code }}</td>
      </ng-container>

      <ng-container matColumnDef="commissionPercentage">
        <th mat-header-cell *matHeaderCellDef>{{ 'commissionPercentage' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.commissionPercentage }}</td>
      </ng-container>

      <ng-container matColumnDef="recruitedDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'recruitedDate' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.recruitedDate) }}</td>
      </ng-container>

      <ng-container matColumnDef="recruitedBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'recruitedBy' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.recruitedBy }}</td>
      </ng-container>
       
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      paginator
      [pageSizeOptions]="[10, 25, resultsLength]"
      [pageSize]="10"
    ></mat-paginator>
  </app-index-container>
  