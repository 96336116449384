import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from '../../_common/snackBar.service';
import { LookupsService } from '../data/lookups.service';
import { customerDocs } from '../data/lookups.dto';
import {regExp} from "../../_common/data/validationRules";

@Component({
  selector: 'app-customer-document-types-popup',
  templateUrl: './customerDocumentTypesPopup.component.html',
})
export class CustomerDocumentTypesPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  documentType: customerDocs;
  constructor(
    private fb: UntypedFormBuilder,
    private lookupsService: LookupsService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<CustomerDocumentTypesPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: customerDocs[],
  ) {
    this.documentType = data[0];
    if (this.documentType) {
      this.addMode = false;
    }
  }

  ngOnInit() {
    const requiredFields = JSON.parse(this.documentType?.requiredFields || '{}');
    this.form = this.fb.group({
      name: [this.documentType?.name, [Validators.required]],
      issueBy: [this.documentType?.issueBy, null],
      code: [this.documentType?.code, [Validators.required]],
      minimumLength: [this.documentType?.minimumLength, [Validators.required]],
      maximumLength: [this.documentType?.maximumLength, [Validators.required]],
      regularExpression: [this.documentType?.regularExpression, null],
      isPrimary: [this.documentType?.isPrimary],
      documentNumberIsRequired: [requiredFields?.documentNumberIsRequired],
      issueDateIsRequired: [requiredFields?.issueDateIsRequired],
      expiryDateIsRequired: [requiredFields?.expiryDateIsRequired],
      documentIssuerIsRequired: [requiredFields?.documentIssuerIsRequired],
      isActive: [this.documentType?.isActive || this.addMode],
    });

  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const documentType: customerDocs = {
        name: formValues.name,
        issueBy: formValues.issueBy,
        code: formValues.code,
        code2: formValues.code2,
        minimumLength: formValues.minimumLength,
        maximumLength: formValues.maximumLength,
        regularExpression: formValues.regularExpression,
        isPrimary: formValues.isPrimary || null,
        requiredFields: JSON.stringify({
          documentNumberIsRequired: formValues.documentNumberIsRequired || false,
          issueDateIsRequired: formValues.issueDateIsRequired || false,
          expiryDateIsRequired: formValues.expiryDateIsRequired || false,
          documentIssuerIsRequired: formValues.documentIssuerIsRequired || false,
        }),
        isActive: formValues.isActive || false
      };

      const handler = this.documentType
        ? this.lookupsService.updateCustomerDocumentType({
            id: this.documentType.id,
            ...documentType,
          })
        : this.lookupsService.createCustomerDocumentType(documentType);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  isRegExp = (regex: any) => {
    if (!isNaN(regex)) return false;

    try {
      return new Function(`
            try {
                new RegExp(${regex});
                return true;
            } catch (e) {
                return false;
            }
        `)();
    } catch (e) {
      return false;
    }
  };

  onFocusout(event: any) {
    if (event.target.value && !this.isRegExp(event.target.value)) {
      this.form.get('regularExpression')?.setErrors({ invalid: true });
    } else {
      this.form.get('regularExpression')?.setErrors(null);
    }
  }
}
