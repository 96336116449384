<h1 mat-dialog-title style="text-align: center">{{ 'Finsurv Reconciliation Details' | translate }}</h1>
<div mat-dialog-content>
    <div role="content" style="font-size: 12px; margin-top: 5px" id="report">
        <div class="row" style="margin: 10px">
            <table id="finsurvTransactions" class="table table-bordered table-hover">
                <thead class="thead-light">
                <tr>
                    <th class="text-nowrap">{{ 'Remittance Code' | translate }}</th>
                        <th class="text-nowrap">{{ 'Sender Name' | translate }}</th>
                        <th class="text-nowrap">{{ 'Sender mobile' | translate }}</th>
                        <th class="text-nowrap">{{ 'Receiver Name' | translate }}</th>
                        <th class="text-nowrap">{{ 'Receiver mobile' | translate }}</th>
                        <th class="text-nowrap">{{ 'Flow' | translate }}</th>
                        <th class="text-nowrap">{{ 'BoP Category' | translate }}</th>
                        <th class="text-nowrap">{{ 'Branch Code' | translate }}</th>
                        <th class="text-nowrap">{{ 'Value Date' | translate }}</th>
                        <th class="text-nowrap">{{ 'Rand Amount' | translate }}</th>
                        <th class="text-nowrap">{{ 'Foreign Amount' | translate }}</th>
                        <th class="text-nowrap">{{ 'Foreign Currency Code' | translate }}</th>
                        <th class="text-nowrap">{{ 'Batch No' | translate }}</th>
                        <th class="text-nowrap">{{ 'Transaction Ref No' | translate }}</th>
                        <th class="text-nowrap">{{ 'Transaction Status' | translate }}</th>
                    </tr>
                  </thead>
                    <tbody>
                    <tr *ngFor="let remittance of data.data">
                        <td class="text-nowrap" color="primary">
                            <span  [ngStyle]="{'color': '#0074d9', 'cursor': 'pointer'}" (click)="onViewHistoryClick(remittance.remittanceId)">
                            {{ remittance.remittanceCode }}
                            </span>
                        </td>
                        <td class="text-nowrap">{{ remittance.senderFullName }}</td>
                        <td class="text-nowrap">{{ remittance.senderMobile }}</td>
                        <td class="text-nowrap">{{ remittance.receiverFullName }}</td>
                        <td class="text-nowrap">{{ remittance.receiverMobile }}</td>

                        <td class="text-nowrap">{{ remittance.flow }}</td>
                        <td class="text-nowrap">{{ remittance.bopCategory }}</td>
                        <td class="text-nowrap">{{ remittance.senderBranch }}</td>
                        <td class="text-nowrap">{{ remittance.pDate }}</td>
                        <td class="text-nowrap">{{ remittance.sendingLocalAmount | number:'1.0-5' }}</td>
                        <td class="text-nowrap">{{ remittance.receivingLocalAmount | number:'1.0-5' }}</td>
                        <td class="text-nowrap">{{ remittance.receivingCurrencyCode }}</td>
                        <td class="text-nowrap">{{ remittance.trnReference }}</td>
                        <td class="text-nowrap">{{ remittance.transactionReference }}</td>
                        <td class="text-nowrap">{{ remittance.tranState }}</td>
                    </tr>
                   </tbody>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-right">
    <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
    <!-- <button mat-button class="btn btn-outline-primary" [useExistingCss]="true" printSectionId="report" ngxPrint>
        {{ 'print' | translate }}
    </button> -->
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
        {{ 'export' | translate }}
    </button>
</div>