<div class="dialog-container">
  <div class="image-gallery">
    <div class="image-container" *ngFor="let image of images">
      <img [src]="image.src" [alt]="image.alt" />
      <div class="image-info">
        <div class="image-title">{{ image.title }}</div>
        <div class="face-match" *ngIf="image.isFaceIdentical && image.faceConfidence">
          Identical: {{ image.isFaceIdentical }} <br>  Confidence Level: {{ image.faceConfidence }}
        </div>
      </div>
    </div>
  </div>
</div>
