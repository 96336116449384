import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerFilter, CustomerService } from './data/customer.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UploadDialogComponent } from './uploadExcelFile.component';
import { Utilities } from '../_common/utils';
import { ClientScreeningCheckConponent } from './clientScreeningCheck.component';
import { SnackBarService } from '../_common/snackBar.service';


@Component({
    selector: 'app-customers',
    templateUrl: './clientScreening.component.html',
    styleUrls: ['../_common/styles/table.less'],
})
export class ClientScreeningConponent implements OnInit {
    // @ts-ignore
    @ViewChild(MatPaginator) paginator: MatPaginator;
    form!: UntypedFormGroup;

    permissions = Permissions.customerManagement.blackListSanctionAuditLogs;
    loading = false;
    resultsLength = 0;
    displayedColumns: string[] = [
      'inputFileName',
      'outputFileName',
      'outputFilePath',
      'dateImported',
      'actions'
    ];
    dataSource = new MatTableDataSource<any>();
    constructor(
        private fb: UntypedFormBuilder,
        private customerService: CustomerService,
        public authService: AuthService,
        private dialog: MatDialog,
        private snackBar: SnackBarService,
    ) {
        
    }


    ngOnInit() {
      this.form = this.fb.group({
        name: [null],
      });
    }


    onSearch(){
      this.loadData();
    }

    onSubmit() {
        this.dialog.open(UploadDialogComponent, {
          disableClose: true,
          width: '400px',
        });
      }
      
      loadData() {
        this.customerService.sanctionImportList.subscribe(
            ({ sanctionImportList }) => {
              this.loading = false;
              this.dataSource.data = sanctionImportList;
              this.dataSource.paginator = this.paginator;
              this.resultsLength = sanctionImportList.length;
            },
          );

          const { name} = this.form.value || '';
          this.customerService.getSanctionImportList(name);
      }
      

    viewSanctionedCheck(id: number) {
        this.dialog.open(ClientScreeningCheckConponent, {
            disableClose: false,
            width: '54rem',
            data: id,
        });
    }

    convertDateToLocaleString(date: Date, type: string) {
      return Utilities.convertDateToLocaleString(date, '');
  }

   downloadFile(filePath: string) {
    this.customerService.getSanctionScreeningResultFile(filePath).subscribe(
    (blob: Blob) => {
        const url = window.URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.href = url;
        a.download = this.extractFileName(filePath);
        document.body.appendChild(a);
        a.click();
        
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, ({message}) => {
        this.snackBar.open(message);
      }
    );
  }

  private extractFileName(path: string): string {
    return path.split('/').pop() || 'downloaded-file';
  }
}
