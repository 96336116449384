import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from './data/customer.service';
import { SnackBarService } from '../_common/snackBar.service';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
})
export class UploadDialogComponent {
  selectedFile: any;
  errorMessage: string = '';
  parsedData: any[] = [];

  constructor(
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private snackBar: SnackBarService,
  ) { }


  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.selectedFile = file
      const fileType = file.name.split('.').pop()?.toLowerCase() || '';

      if (['xls', 'xlsx', 'csv'].includes(fileType)) {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (event: ProgressEvent<FileReader>) => {
          const arrayBuffer = event.target?.result as ArrayBuffer;
          const data = new Uint8Array(arrayBuffer);
          const workbook = XLSX.read(data, { cellDates: true, type: 'array' });

          if (workbook.SheetNames.length > 1) {
            this.errorMessage = 'Excel file should contain only one sheet';
            this.selectedFile = [];
            return;
          }
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData: any[] = XLSX.utils.sheet_to_json(worksheet);
          const header = jsonData.shift();

          const validationResult = this.validateHeaders(header);

          if (!validationResult.isValid) {
            this.errorMessage = validationResult.message;
            this.selectedFile = [];
            return;
          }


          if (jsonData.length > 0) {
            const lowerCasedJsonData = jsonData.map(row => {
              const lowerCaseRow: { [key: string]: any } = {};
              Object.keys(row).forEach(key => {
                lowerCaseRow[key.toLowerCase()] = row[key];
              });
              return lowerCaseRow;
            });

            this.parsedData = lowerCasedJsonData;
          }

        };

      } else {
        this.errorMessage = 'Please upload a valid Excel file (.xls or .xlsx, .csv)';
        this.selectedFile = [];
      }
    }
  }

  private validateHeaders(headers: any): any {
    const requiredHeaders = ['customerfullname'];
    const optionalHeaders = ['middle name', 'date of birth'];

    const normalizedHeaders = Object.keys(headers).map(h => h.toLowerCase().trim());

    const hasRequiredHeaders = requiredHeaders.every(req => normalizedHeaders.includes(req));
    if (!hasRequiredHeaders) {
      return { isValid: false, message: 'Missing required headers: customerfullname' };
    }

    const invalidHeaders = normalizedHeaders.filter(header =>
      !requiredHeaders.includes(header) && !optionalHeaders.includes(header.toLowerCase())
    );

    const hasValidDateFormat = this.parsedData.every(row => this.isValidDateFormat(row['date of birth'] || ''));
    if (!hasValidDateFormat) {
      return { isValid: false, message: 'Invalid date format found in the "date of birth" field' };
    }

    return { isValid: true, message: 'Headers and data are valid' };
  }

  private isValidDateFormat(dateString: string): boolean {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    return datePattern.test(dateString);
  }



  onUpload() {
    if (this.parsedData.length > 0) {
      this.customerService.importExcelData(this.selectedFile).subscribe(
        (resp) => {
          this.dialogRef.close(true);
          this.snackBar.open(resp?.message);
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    } else {
      this.snackBar.open("records of this file is empty");
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
