import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../_common/data/http.service';
import {GetRecruitersDto, SearchCustomerResponseDto } from 'src/app/customer/data/dto/customer.dto';
import { AuthService } from 'src/app/auth/data/auth.service';
import { TableTypes } from 'src/app/_common/enums/tableTypes.enums';
import { CustomerFilter } from 'src/app/referal/data/referl.service';


export type RecruiterFilter = {
  recruiterCode?: string;
  recruiterName?: string;
};


type GetRecruiters = {
  appliedFilters: RecruiterFilter | null;
  recruiters: GetRecruitersDto[];
};


@Injectable()
export class RecruitersService {
  private tablePendingChanges: string = '';
  private _recruiters = new BehaviorSubject<GetRecruiters>({
    appliedFilters: null,
    recruiters: [],
  });
 
  private customersObservable?: Observable<GetRecruiters>;
  constructor(private httpService: HttpService, private authService: AuthService) {}

  get recruiters() {
    if (!this.customersObservable)
        this.customersObservable = this._recruiters.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.RECRUITERS) {
            this.searchRecruiters(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.customersObservable;
  }

  searchRecruiters(filter: RecruiterFilter | null) {
    console.log("searchRecruiters", filter);
    this.httpService
      .get<GetRecruitersDto[]>('referals/recruiters', filter)
      .subscribe((recruiters) => {
        this.tablePendingChanges = '';
        this._recruiters.next({
          recruiters: recruiters,
          appliedFilters: filter,
        });
      });
  }
}
