import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerFilter, CustomerService } from './data/customer.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerDocPopupComponent } from './customerDocPopup.component';
import { SnackBarService } from '../_common/snackBar.service';
import { CustomerTypes } from "../_common/enums/systemBranches.enums";
import { Utilities } from '../_common/utils';
import { OrmCustomerActionsComponent } from './ormcustomeractions.component';

@Component({
  selector: 'app-customers',
  templateUrl: './ormcustomer.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class OrmCustomersComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  statusId: number | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  limit: number | undefined;
  permissions = Permissions.customerManagement.customers;
  loading = false;
  resultsLength = 0;
  isSenderPartnerSelected: Boolean = false;
  displayedColumns: string[] = [
    'number',
    'customerId',
    'firstName',
    'middleName',
    'lastName',
    'mobile',
    'city',
    'customerCreatedDate',
    'customerOnboardDate',
    'customerStatus',
    'actions',
  ];
  customerTypes = CustomerTypes
  data: any = new MatTableDataSource<any>();
  customerStatusId: any = null;
  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    public authService: AuthService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data_: any,
    public dialogRef: MatDialogRef<CustomerDocPopupComponent>,
    public dialogRefActions: MatDialogRef<OrmCustomerActionsComponent>
  ) {
    this.statusId = data_?.statusId || null;
    this.startDate = data_?.startDate || null;
    this.endDate = data_?.endDate || null;
    this.limit = data_?.limit || null;
  }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    const filter: CustomerFilter = {
      customerStatusId: this.statusId || undefined,
      startDate: this.startDate ? this.startDate : null,
      endDate: this.endDate ? this.endDate : null,
      limit: this.limit
    };

    this.customerService.customers.subscribe(
      ({ customers, appliedFilters }) => {
        this.loading = false;
        this.data.data = customers;
        this.data.paginator = this.paginator;
        this.resultsLength = customers.length;
      },
    );

    this.customerService.searchCustomers(filter);
  }


  handleBlacklistAction(customerId: number, blacklist: boolean) {
    if (blacklist) {
      this.customerService.removeCustomerFromBlacklist(customerId).subscribe(
        (resp) => {
          this.snackBar.open(resp?.data?.message);
          this.loadData();
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
      return;
    }

    this.customerService.addCustomerToBlacklist(customerId).subscribe(
      (resp) => {
        this.snackBar.open(resp?.data.message);
        this.loadData();
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  openActionsDialog(customerId: number, customerTypeId: number) {
    this.customerService.getCustomerDocs(customerId).subscribe((docs) => {
      const dialogRef = this.dialog.open(OrmCustomerActionsComponent, {
        width: '1400px',
        height: '600px',
        disableClose: true,
        data: { docs, customerId, customerTypeId },
      });
      dialogRef.afterClosed().subscribe((result) => { 
        this.loadData();
      });
    }, ({ message }) => {
      this.loadData();
    });
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
