import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-show-generated-password',
  templateUrl: './generatePassword.component.html',
  styleUrls: ['./generatePassword.component.less']
})
export class ShowGeneratedPasswordComponent implements OnInit {
  password: string = '';
  username: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { password: string, username: string },
    private dialogRef: MatDialogRef<ShowGeneratedPasswordComponent>,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.password = this.data.password;
    this.username = this.data.username;
  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
  }

  close() {
    this.dialogRef.close();
  }
}