import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { RecruitersComponent } from './recruiters.component';

const {
  RECRUITERS_MANAGEMENT: { recruiters },
} = Permissions;

const routes: Routes = [
  {
    path: 'recruiters',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: Object.values(recruiters) },
        component: RecruitersComponent,
      },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecruitersRoutesModule {}
