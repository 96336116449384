
<app-index-container label="{{ 'ClientScreening' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSearch()">
        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-end">
                    <!-- import button -->
                    <button (click)="onSubmit()" mat-raised-button type="button" color="primary">
                        <i class="fa-solid fa-file-import"></i>
                        {{ 'Import' | translate }}
                    </button>

                     <!-- Search button -->
                     <button mat-raised-button type="submit" color="primary"  style="margin-left: 10px" >
                        <i class="fa-solid fa-search"></i>
                        {{ 'Search' | translate }}
                    </button>

                </div>
            </div>
        </div>        
    </form>

    <table table mat-table [dataSource]="dataSource" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>

        <!-- inputFileName Column -->
        <ng-container matColumnDef="inputFileName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Input FileName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.inputFileName }}</td>
        </ng-container>

        <!-- outputFileName Column -->
        <ng-container matColumnDef="outputFileName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Output FileName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.outputFileName }}</td>
        </ng-container>

         <!-- outputFilePath Column -->
         <ng-container matColumnDef="outputFilePath">
            <th mat-header-cell *matHeaderCellDef>{{ 'Output FilePath' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.outputFilePath }}</td>
        </ng-container>

        <!-- date Imported Column -->
        <ng-container matColumnDef="dateImported">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'dateImported' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ convertDateToLocaleString(row.dateCreated,  'datetime')}}
            </td>
        </ng-container>

       <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <div class="action-button-menu">
                    <button 
                    class="btn btn-outline-info btn-sm" 
                    (click)="downloadFile(row.outputFilePath)">
                    {{ 'Export' | translate }}
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>