import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { UsersService } from './data/users.service';
import { GetUsersDto } from './data/dto/user/users.dto';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { ResetPasswordPopupComponent } from './resetPasswordPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { ShowGeneratedPasswordComponent } from './generatePassword.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class UsersComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  username = '';

  permissions = Permissions.userManagement.users;
  resultsLength = 0;
  displayedColumns: string[] = [
    'number',
    'username',
    'name',
    'role',
    'userType',
    'branch',
    'status',
    'actions',
  ];
  data = new MatTableDataSource<GetUsersDto>();
  userTypes!: LookupDto[];

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    public authService: AuthService,
    public dailog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      userName: [null],
      branchCode: [null],
      statusId: [null],
      userTypeId: [null],
    });

    this.usersService.users.subscribe(({ users, appliedFilters }) => {
      const currentUserId = this.authService.currentUserValue?.id;
      this.data.data = users.filter((user) => user.id != currentUserId);
      this.data.paginator = this.paginator;
      this.resultsLength = users.length;
      this.form.controls['userName'].setValue(appliedFilters?.userName);
      this.form.controls['branchCode'].setValue(appliedFilters?.branchCode);
      this.form.controls['statusId'].setValue(appliedFilters?.statusId);
      this.form.controls['userTypeId'].setValue(appliedFilters?.userTypeId);
    });
    this.authService.currentUser.subscribe((user) => {
      this.username = user.username;
    });

    this.usersService.userTypes().subscribe((type) => {
      this.userTypes = type;
    });
  }

  onSubmit() {
    const formValue = this.form.value;
    this.usersService.getUsers(formValue);
  }

  resetUserPassword(id: number) {
    this.dailog.open(ResetPasswordPopupComponent, {
      disableClose: true,
      autoFocus: false,
      width: '500px',
      data: { id },
    });
  }

  canResetPassword(username: string) {
    return (
      this.authService.isUserAuthorized(this.permissions.RESET_PASSWORD) &&
      this.username !== username
    );
  }

  generatePassword(id: number) {
    this.usersService.generatePassword(id).subscribe(
      (response: { password: string, username: string }) => {
        this.dailog.open(ShowGeneratedPasswordComponent, {
          disableClose: true,
          autoFocus: false,
          width: '500px',
          data: { 
            password: response.password,
            username: response.username
          }
        });
      }
    );
  }
}
