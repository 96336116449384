import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { DashboardComponent } from './_common/components/dashboard/dashboard.component';

import { ContainersModule } from './_common/components/containers/containers.module';
import { UserModule } from './user/user.module';
import { BranchModule } from './branch/branch.module';
import { RemittanceModule } from './remittance/remittance.module';
import { CustomerModule } from './customer/customer.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoaderModule } from './_common/components/loader/loader.module';
import { AuthModule } from './auth/auth.module';
import { ComplianceModule } from './compliance/compliance.module';
import { NotificationModule } from './notification/notification.module';
import { SettlementModule } from './settlement/settlement.module';
import { ReportModule } from './report/report.module';
import { LookupsModule } from './lookups/lookups.module';
import { SupportModule } from './support/support.module';
import { AutoLogoutService } from './auth/data/autoLogout.service';
import { ValidationErrorModule } from './_common/components/validation-error/validation-error.module';
import {CbkReportsModule} from "./cbkreports/cbkreports.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTableModule} from "@angular/material/table";
import {MatTableExporterModule} from "mat-table-exporter";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS } from './_common/utils';
import { OrmComponent } from './remittance/orm.component';
import { OrmCustomersComponent } from './customer/ormcustomers.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { OrmRemittancesComponent } from './remittance/ormremittance.component';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommentPopupComponent } from './customer/commentPopup.compoent';
import { ReferalModule } from './referal/referal.module';
import { RecruitersModule } from './recruiters/recruiters.module';


@NgModule({
  declarations: [AppComponent, DashboardComponent, OrmComponent, CommentPopupComponent, OrmCustomersComponent, OrmRemittancesComponent  ],
  imports: [
    BrowserModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatMenuModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    AuthModule,
    ContainersModule,
    LoaderModule,
    UserModule,
    BranchModule,
    RemittanceModule,
    CustomerModule,
    ComplianceModule,
    NotificationModule,
    SettlementModule,
    ReportModule,
    LookupsModule,
    SupportModule,
    CbkReportsModule,
    ReferalModule,
    RecruitersModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ValidationErrorModule,
    MatDatepickerModule,
    MatTableModule,
    MatTableExporterModule,
    CommonModule,
    MatTooltipModule, 
  ],
  exports: [OrmComponent],
  providers: [
    AutoLogoutService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500 },
    },{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
