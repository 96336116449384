export const Permissions = Object.freeze({
  userManagement: {
    roles: {
      CREATE_ROLE: 'createRole',
      UPDATE_ROLE: 'updateRole',
      VIEW_ROLE: 'viewRole',
    },
    users: {
      CREATE_USER: 'createUser',
      UPDATE_USER: 'updateUser',
      VIEW_USER: 'viewUser',
      VIEW_USER_PROFILE: 'viewUserProfile',
      RESET_PASSWORD: 'resetPassword',
    },
    logs: {
      VIEW_LOG: 'viewLog',
    },
    roleRequests: {
      VIEW_ROLE_REQUESTS: 'viewRoleRequest',
      UPDATE_ROLE_REQUESTS: 'updateRoleRequest',
    },
    userRequests: {
      VIEW_USER_REQUESTS: 'viewUserRequest',
      UPDATE_USER_REQUESTS: 'updateUserRequest',
    },
    loginHistories: {
      VIEW_LOGIN_HISTORY: 'viewLoginHistory',
    },
    isActive: true
  },
  branchManagement: {
    branches: {
      CREATE_BRANCH: 'createBranch',
      UPDATE_BRANCH: 'updateBranch',
      VIEW_BRANCH: 'viewBranch',
    },
    rateChangeRequests: {
      VIEW_BRANCH_RATE_CHANGE_REQUESTS: 'viewBranchRateChangeRequests',
      UPDATE_BRANCH_RATE_CHANGE_REQUESTS: 'updateBranchRateChangeRequests',
    },
    limitChangeRequests: {
      VIEW_BRANCH_LIMIT_CHANGE_REQUESTS: 'viewBranchLimitChangeRequests',
      UPDATE_BRANCH_LIMIT_CHANGE_REQUESTS: 'updateBranchLimitChangeRequests',
    },
    commissionChangeRequests: {
      VIEW_BRANCH_COMMISSION_CHANGE_REQUESTS:
        'viewBranchCommissionChangeRequests',
      UPDATE_BRANCH_COMMISSION_CHANGE_REQUESTS:
        'updateBranchCommissionChangeRequests',
    },
    branchCommissions: {
      VIEW_BRANCH_COMMISSIONS: 'viewBranchCommissions',
      PROCESS_BRANCH_COMMISSIONS: 'processBranchCommission',
    },
    manualJournals: {
      CREATE_MANUAL_JOURNAL: 'createManualJournal',
      UPDATE_MANUAL_JOURNAL: 'updateManualJournal',
      VIEW_MANUAL_JOURNAL: 'viewManualJournals',
      VIEW_MANUAL_JOURNALS_ATTACHMENT : 'viewManualJournalAttachment',
      APPROVE_MANUAL_JOURNAL: 'approveManualJournal',
      REJECT_MANUAL_JOURNAL: 'rejectManualJournal',
    },
    isActive: true
  },
  remittanceManagement: {
    remittances: {
      CREATE_REMITTANCE: 'createRemittance',
      PAY_REMITTANCE: 'payRemittance',
      APPROVE_BRANCH_VIOLATION: 'approveBranchViolation',
      APPROVE_COMPLIANCE_VIOLATION: 'approveComplianceViolation',
      UNFREEZE_REMITTANCE: 'unfreezeRemittance',
      VIEW_CHANGE_LOG: 'viewChangeLog',
      VIEW_REMITTANCE_CODE: 'viewRemittanceCode',
    },
    ambiguousRemittances: {
      VIEW_AMBIGUOUS_REMITTANCES: 'viewAmbiguousRemittances',
      RESOLVE_AMBIGUOUS_REMITTANCE: 'resolveAmbiguousRemittance'
    },
    ormdashboard: {
      VIEW_ORM_DASHBOARD: 'viewORMDASHBOARD',
    },
    commissionConfiguration: {
      CREATE_REMIT_COMM_CONFIG: 'createRemitCommConfig',
      UPDATE_REMIT_COMM_CONFIG: 'updateRemitCommConfig',
      VIEW_REMIT_COMM_CONFIG: 'viewRemitCommConfig',
    },
    commissionConfigurationRequests: {
      VIEW_REMITTANCE_COMMISSION_STRUCTURE_REQUEST:
        'viewRemittanceCommissionStructureRequest',
      UPDATE_REMITTANCE_COMMISSION_STRUCTURE_REQUEST:
        'updateRemittanceCommissionStructureRequest',
    },
    notifications: {
      VIEW_NOTIFICATION: 'viewNotification',
      RETRY_NOTIFICATION: 'retryNotification',
    },
    finsurv: {
      VIEW_FINSURV_REPORT: 'viewFinsurvReport'
    },
    finsurvTransactions: {
      VIEW_FINSURV_TRANSACTIONS: 'viewFinsurvTransactions',
    },
    isActive: true
  },
  accountsManagement: {
    accounts: {
      CREATE_ACCOUNT: 'createAccount',
      GET_ACCOUNTS: 'getAccounts',
      UPDATE_ACCOUNT: 'updateAccount',
    },
    isActive: true
  },
  manualJournals: {
    journals: {
      CREATE_MANUAL_JOURNAL: 'createManualJournal',
      UPDATE_MANUAL_JOURNAL: 'updateManualJournal',
      VIEW_MANUAL_JOURNAL: 'viewManualJournals',
      APPROVE_MANUAL_JOURNAL: 'approveManualJournal',
      REJECT_MANUAL_JOURNAL: 'rejectManualJournal',
    },
    isActive: true
  },
  complianceManagement: {
    complianceConfigurations: {
      CREATE_COMPLIANCE_CONFIG: 'createComplianceConfig',
      UPDATE_COMPLIANCE_CONFIG: 'updateComplianceConfig',
      VIEW_COMPLIANCE_CONFIG: 'viewComplianceConfig',
    },
    searchSanctions: {
      VIEW_SANCTIONS: 'viewSearchSanctions',
    },
    isActive: true
  },
  customerManagement: {
    customers: {
      CREATE_CUSTOMER: 'upsertCustomer',
      UPDATE_CUSTOMER: 'updateCustomer',
      VIEW_CUSTOMER: 'viewCustomer',
      CONFIG_CUSTOMER_BLACKLIST: 'configCustomerBlacklist',
    },
    blackList: {
      CREATE_BLACKLIST: 'upsertBLACKLIST',
      UPDATE_BLACKLIST: 'updateBLACKLIST',
      VIEW_BLACKLIST: 'viewBLACKLIST',
    },
    blackListSanctionAuditLogs: {
      VIEW_BLACKLIST_SACTIONED_AUDIT_LOGS: 'viewBlacklistSanctionedLogs',
    },
    ClientScreening: {
      VIEW_CLIENT_SCREENING: 'ViewClientScreening',
    },
    isActive: true
  },
  reportManagement: {
    REMITANCE_ROPORT: {
      VIEW_REMITTANCE_REPORT: 'viewRemittanceReport',
    },
    MONTHLY_COMMISSIONS_REPORT: {
      VIEW_MONTHLY_COMMISSIONS_REPORT: 'viewMonthlyCommissionsReport',
    },
    TRIAL_BALANCE_REPORT: {
      VIEW_TRIAL_BALANCE_REPORT: 'viewTrialBalanceReport',
    },
    BRANCH_STATEMENT_REPORT: {
      VIEW_BRANCH_STATEMENT_REPORT: 'viewBranchStatementReport',
    },
    SETTLEMENT_REPORT: {
      VIEW_SETTLEMENT_REPORT: 'viewSettlementReport',
    },
    ACCOUNT_ACTIVITY_REPORT: {
      VIEW_ACCOUNT_ACTIVITY_REPORT: 'viewAccountActivityReport',
    },
    UNPAID_REMITTANCE_REPORT: {
      VIEW_UNPAID_REMITTANCE_REPORT: 'viewUnPaidRemittanceReport',
    },
    UNPAID_REMITTANCE_REPORT_Detail: {
      VIEW_UNPAID_REMITTANCE_REPORT_Detail: 'viewUnPaidRemittanceReportDetail',
    },
    COMPLIANCE_REPORT: {
      VIEW_COMPLIANCE_REPORT: 'viewComplianceReport',
    },
    COMPLIANCE_REPORT_Detail: {
      VIEW_COMPLIANCE_REPORT_Detail: 'viewComplianceReportDetail',
    },
    PARTNER_SETTLEMENT_REPORT: {
      VIEW_PARTNER_SETTLEMENT_REPORT: 'viewPartnerSettlementReport',
    },
    CUSTOMER_REPORT: {
      VIEW_CUSTOMER_REPORT: 'viewCustomerReport',
    },
    FINSURV_RECONCILIATION_REPORT: {
      VIEW_FINSURV_RECONCILIATION_REPORT: 'viewFinsurvReconciliationReport',
    },
    CUSTOMER_RISK_RATING_REPORT: {
      VIEW_CUSTOMER_RISK_RATING_REPORT: 'viewCustomerRiskRatingReport',

    },
    KITEWORK_TRANSACTIONS: {
      VIEW_KITEWORK_TRANSACTIONS: 'viewKiteworkTransactions',
    },
    isActive: true
  },
  settlementManagement: {
    settlements: {
      VIEW_SETTLEMENT: 'viewSettlement',
      REJECT_SETTLEMENT: 'rejectSettlement',
      CONFIRM_SETTLEMENT: 'confirmSettlement',
      CREATE_SETTLEMENT: 'createSettlement',
      TRANSFER_SETTLEMENT: 'transferSettlement',
      ROLLBACK_SETTLEMENT: 'rollbackSettlement',
    },
    isActive: true
  },
  lookupsManagement: {
    remittancePurposes: {
      VIEW_REMITTANCE_PURPOSE_LOOKUP: 'viewRemittancePurposeLookup',
      ADD_REMITTANCE_PURPOSE_LOOKUP: 'addRemittancePurposeLookup',
      UPDATE_REMITTANCE_PURPOSE_LOOKUP: 'updateRemittancePurposeLookup',
    },
    remittanceRelationships: {
      VIEW_REMITTANCE_RELATIONSHIP_LOOKUP: 'viewRemittanceRelationshipLookup',
      ADD_REMITTANCE_RELATIONSHIP_LOOKUP: 'addRemittanceRelationshipLookup',
      UPDATE_REMITTANCE_RELATIONSHIP_LOOKUP:
        'updateRemittanceRelationshipLookup',
    },
    occupations: {
      VIEW_OCCUPATION_LOOKUP: 'viewOccupationsLookup',
      ADD_OCCUPATION_LOOKUP: 'addOccupationsLookup',
      UPDATE_OCCUPATION_LOOKUP: 'updateOccupationsLookup',
    },
    sourceOfFunds: {
      VIEW_SOURCE_OF_FUNDS_LOOKUP: 'viewSourceOfFundsLookup',
      ADD_SOURCE_OF_FUNDS_LOOKUP: 'addSourceOfFundsLookup',
      UPDATE_SOURCE_OF_FUNDS_LOOKUP: 'updateSourceOfFundsLookup',
    },
    cities: {
      VIEW_CITY_LOOKUP: 'viewCityLookup',
      ADD_CITY_LOOKUP: 'addCityLookup',
      UPDATE_CITY_LOOKUP: 'updateCityLookup',
    },
    localities: {
      VIEW_LOCALITY_LOOKUP: 'viewLocalityLookup',
      ADD_LOCALITY_LOOKUP: 'addLocalityLookup',
      UPDATE_LOCALITY_LOOKUP: 'updateLocalityLookup',
    },
    customerDocumentTypes: {
      VIEW_CUSTOMER_DOCUMENT_TYPES_LOOKUP: 'viewCustomerDocumentTypesLookup',
      ADD_CUSTOMER_DOCUMENT_TYPES_LOOKUP: 'addCustomerDocumentTypesLookup',
      UPDATE_CUSTOMER_DOCUMENT_TYPES_LOOKUP:
        'updateCustomerDocumentTypesLookup',
    },
    isActive: true
  },
  ticketManagement: {
    tickets: {
      VIEW_TICKET: 'viewTicket',
      CREATE_TICKET: 'createTicket',
      UPDATE_TICKET: 'updateTicket',
      ADD_COMMENT: 'addComment',
    },
    isActive: true
  },
  CBK_REPORT: {
    incomingRemittance: {
      VIEW_INCOMING_REMITTANCE_REPORT: 'viewIncomingRemittanceReport',
    },
    outgoingRemittance: {
      VIEW_OUTGOING_REMITTANCE_REPORT: 'viewOutgoingRemittanceReport',
    },
    bsmvTax: {
      VIEW_BSMV_TAX_REMITTANCE_REPORT: 'viewBSMVTaxRemittanceReport',
    },
    clientProtection: {
      VIEW_CLIENT_PROTECTION_REMITTANCE_REPORT: 'ViewClientProtectionAccount',
    },
    clientProtectionCustomer: {
      VIEW_CLIENT_PROTECTION_CUSTOMER_REPORT: 'ViewClientProtectionCustomerReport',
    },
    btransReport: {
      VIEW_BTRANS_PARQUET_REPORT: 'viewBtransParquetReport'
    },
    isActive: false
  },
  REFERAL_MANAGEMENT: {
    referals: {
      VIEW_REFERAL: 'viewReferalDashboard',
    },
    isActive: true
  },
  RECRUITERS_MANAGEMENT: {
    recruiters: {
      VIEW_RECRUITERS: 'viewRecruiters',
    },
    isActive: true
  },
});
