import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../_common/enums/permissions.enums';
import { UserTypes } from '../_common/enums/userTypes.enums';
import { UsersService, passwordRegex } from './data/users.service';
import { RolesResponseDto } from './data/dto/role/roles.dto';
import { LookupDto } from '../_common/data/dto/api.dto';
import { UserDto } from './data/dto/user/users.dto';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AuthService } from '../auth/data/auth.service';
import { SystemBranchCodes } from '../_common/enums/systemBranches.enums';
import { CustomValidators } from '../_common/data/customValidators';
import { regExp } from '../_common/data/validationRules';
import { BranchesService } from '../branch/data/branches.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-user-details',
  templateUrl: './userDetails.component.html',
})
export class UserDetailsComponent implements OnInit {
  form!: UntypedFormGroup;
  passwordPattern = passwordRegex;

  permissions = Permissions.userManagement.users;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;

  userTypes: LookupDto[] = [];
  roles: RolesResponseDto[] = [];
  userTypeRoles: RolesResponseDto[] = [];
  allBranches: GetBranchesDto[] = [];
  branches: GetBranchesDto[] = [];
  branchCurrencies: LookupDto[] = [];
  selectedCurrency: any;
  isTellerChecked: boolean = false;
  otpTypes?: any[];

  user?: UserDto;
  addMode = true;
  title = '';

  isTeller!: boolean;
  isActive!: boolean;
  canActivate: boolean = true;
  canBeEdited: boolean | undefined;
  otpEnabled!: boolean;
  currencyCode: string = '';
  hasAlreadyTellerAccount = false;
  isReferralUserType = false;
  maxCommissionPercent: number | undefined;
  commissionPercent: number | undefined;
  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
    private branchesService: BranchesService,
  ) {}

  ngOnInit() {
    this.getMaxCommissionPercent();
    this.route.data.subscribe(
      ({ userTypes, roles, users, branches, addMode }) => {
        this.addMode = addMode;
        this.userTypeRoles = [];
        this.userTypes = userTypes;
        this.roles = roles;
        this.roles = this.roles.filter((role)=> role.isActive === true);
        this.allBranches = branches;
        if (users && users.length) {
          this.user = users[0];
        }

        if (!addMode)
          this.canActivate =
            this.user?.createdBy !== this.authService.currentUserValue?.id;

          this.canBeEdited = this.user?.isActive === true || this.user === undefined;

        const formFields: any = {
          firstName: [
            this.user?.firstName,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          lastName: [
            this.user?.lastName,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          username: [
            this.user?.username,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(regExp.alpha),
            ],
          ],
          email: [
            this.user?.email,
            [Validators.required, Validators.pattern(regExp.emailRegex)],
          ],
          mobilePhone: [
            this.user?.mobilePhone,
            [Validators.required, Validators.pattern(regExp.mobile)],
          ],
          userTypeId: [this.user?.userTypeId, [Validators.required]],
          roleId: [this.user?.roleId, [Validators.required]],
          branchId: [this.user?.branchId, [Validators.required]],
          isTeller: [this.user?.isTeller],
          isActive: [this.user?.isActive],
          openingHours: [this.user?.workingHours?.openingHours],
          closingHours: [this.user?.workingHours?.closingHours],
          otpEnabled: [null],
          otpTypeId: [this.user?.otpTypeId],
          commissionPercent: [this.user?.commissionPercent || 0.1]
        };

        this.usersService.getOtpTypes().subscribe((type) => {
          this.otpTypes = type;
        });
        if (this.addMode) {
          formFields.password = [null, [Validators.required]];
        }

        formFields.branchCurrencyId = [null];
        formFields.tellerAccountName = [null];

        this.form = this.fb.group(formFields, {
          validators: CustomValidators.conditionalRequired(
            'openingHours',
            'closingHours',
          ),
        });
        if (this.user) {
          this.onUserTypeChange(this.user.userTypeId);
        }

        if (this.addMode) {
          this.title = 'addUser';
        } else if (
          this.authService.isUserAuthorized(this.permissions.UPDATE_USER)
        ) {
          this.title = 'updateUser';
        } else {
          this.title = 'viewUser';
          this.form.disable();
        }

        if(!this.addMode) this.onBranchChange(this.user?.branchId as number);

        this.hasAlreadyTellerAccount = this.user?.isTeller as boolean;
      },
    );

    this.otpEnabled =
      this.user?.otpTypeId == '1' || this.user?.otpTypeId == '2';
    if (this.otpEnabled) {
      this.form.controls['otpEnabled'].setValue(1);
    }
  }
  radioChange(event: any) {
    if (event.value == 1) {
      this.form.controls['otpTypeId'].setValidators([Validators.required]);
      this.form.controls['otpTypeId'].updateValueAndValidity();
    } else {
      this.form.controls['otpTypeId'].removeValidators([Validators.required]);
      this.form.controls['otpTypeId'].updateValueAndValidity();
    }
  }
  onUserTypeChange(userTypeId: number) {
    if (this.form) {
      this.userTypes.map((userType) => {
        if (userType.id === userTypeId && (userType.name === UserTypes.BRANCH || userType.name === UserTypes.HQ)) {
          this.isTeller = true;
          this.isReferralUserType = false;
          this.branches = this.allBranches.filter(
            (f) => f.code !== SystemBranchCodes.TKCPO,
          );
        } else if (
          userType.id === userTypeId &&
          userType.name === UserTypes.ADMIN
        ) {
          this.isTeller = false;
          this.isReferralUserType = false;
          this.branches = this.allBranches.filter(
            (f) => f.code === SystemBranchCodes.TKCPO,
          );
        }else if(userType.id === userTypeId && (userType.name === UserTypes.ORM)){
          this.isReferralUserType = false;
        } else if(userType.id === userTypeId && (userType.name === UserTypes.REFERAL)){
          this.isReferralUserType = true;
          this.branches = this.allBranches.filter(
            (f) => f.code !== SystemBranchCodes.TKCPO,
          );
        }
      });
    }
    const currenUserPermissions =
      this.authService.currentUserValue?.permissions;
    const userTypeFilteredRoles = this.roles.filter(
      (r) => r.userTypeId === userTypeId,
    );
    this.userTypeRoles = userTypeFilteredRoles.filter((permission) =>
      permission.permissionNames?.every((p) =>
        currenUserPermissions?.includes(p),
      ),
    );
  }

  onSubmit() {
    if (this.form.valid) {
      
      const { value: formData } = this.form || {};
      formData.isTeller = formData.isTeller || false;
      if (this.addMode && formData.isTeller) {
        formData.tellerAccountName = `${formData.tellerAccountName}`;
        formData.tellerAccountCurrency = this.currencyCode;
        formData.tellerAccountCurrencyId = this.selectedCurrency?.id;
      } else {
        delete formData.tellerAccountName;
        delete formData.tellerAccountCurrency;
        delete formData.tellerAccountCurrencyId;
      }
      if (!this.addMode) {
        formData.isActive = formData.isActive;
      }
      if (!this.addMode && formData.otpEnabled == 0) {
        formData.otpTypeId = null;
      }
      if (formData.openingHours && formData.closingHours) {
        formData.workingHours = {
          openingHours: formData.openingHours,
          closingHours: formData.closingHours,
        };
        delete formData.openingHours;
        delete formData.closingHours;
      }

      const handler = this.user
        ? this.usersService.updateUser({
            oldUser: this.user,
            newUser: {
              id: this.user.id,
              ...formData,
            },
            id: this.user.id,
            ...formData,
          })
        : this.usersService.createUser(formData);

      handler.subscribe(
        (resp) => {
          // @ts-ignore
          this.snackBar.open(resp?.message);
          if (this.addMode) {
            this.resetBtn?.nativeElement.click();
            this.selectedCurrency = {};
            this.isTellerChecked = false;
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }
  onBranchChange(branchId: number) {
    this.branchesService
      .branchCurrenciesLookup(branchId)
      .subscribe((branchCurrencies) => {
        this.branchCurrencies = branchCurrencies;
      });
  }
  onBranchCurrencyChange(currencyId: number) {
    this.selectedCurrency = this.branchCurrencies.find(
      (cr) => cr.id == currencyId,
    );
    const regex = /\(([^)]+)\)/;
    const result = this.selectedCurrency.name.match(regex);
    this.currencyCode = result[1];
    const tellerAccountName = `teller ${this.form.get('username')?.value} (${
      this.currencyCode
    })`;
    this.form.get('tellerAccountName')?.setValue(tellerAccountName);
  }
  onTellerChecked(event: MatCheckboxChange) {
    this.isTellerChecked = event.checked;
    if (this.addMode) {
      if (this.isTellerChecked) {
        this.form.get('branchCurrencyId')?.setValidators(Validators.required);
        this.form.get('tellerAccountName')?.setValidators(Validators.required);
        this.form.get('tellerAccountName')?.updateValueAndValidity();
        this.form.get('branchCurrencyId')?.updateValueAndValidity();
      } else {
        this.form.get('branchCurrencyId')?.setValidators(null);
        this.form.get('tellerAccountName')?.setValidators(null);
        this.form.get('tellerAccountName')?.updateValueAndValidity();
        this.form.get('branchCurrencyId')?.updateValueAndValidity();
      }
    }
  }

  getMaxCommissionPercent() {
    this.usersService.getMaxCommissionPercent()
    .subscribe({
      next: (response: any) => {
        this.maxCommissionPercent = response.config[0].max;
      },
      error: (error) => {
        console.error('Error fetching referral information:', error);
      },
    });
  }
}
