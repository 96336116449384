<h2>{{ 'Processed Commission' | translate }}</h2>
<table table mat-table [dataSource]="remittances" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="remittanceId">
        <th mat-header-cell *matHeaderCellDef>{{ 'remittanceId' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="senderName">
        <th mat-header-cell *matHeaderCellDef> {{ 'senderFullName' | translate}} </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.senderName" matTooltipPosition="after">
            {{ row.senderName }}<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef> {{ 'receiverFullName' | translate}} </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.receiverName" matTooltipPosition="after">
            {{ row.receiverName }} &nbsp;<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{ 'amount ' | translate }}(ZAR)</th>
        <td mat-cell *matCellDef="let row">{{ row.amount | number:'1.2-2' }}</td>
    </ng-container>

    <ng-container matColumnDef="sendDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'sendDate' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.sendDate) }}</td>
    </ng-container>


    <ng-container matColumnDef="commission">
        <th mat-header-cell *matHeaderCellDef>{{ 'Remitt Coma ' | translate }}(ZAR)</th>
        <td mat-cell *matCellDef="let row">{{ row.commission }}</td>
    </ng-container>

    <ng-container matColumnDef="referalCommission">
        <th mat-header-cell *matHeaderCellDef>{{ 'Referal Coma ' | translate }}(ZAR)</th>
        <td mat-cell *matCellDef="let row">{{ row.referalCommission }}</td>
    </ng-container>

    <ng-container matColumnDef="dateProcessed">
        <th mat-header-cell *matHeaderCellDef>{{ 'dateProcessed' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.dateProcessed) }}</td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>       
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
            {{ 'noRecordsAvailable' | translate }}
        </td>
    </tr>
</table>
<mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>