<div class="password-dialog">
    <h2 mat-dialog-title>Generated Password</h2>
    
    <mat-dialog-content>
      <div class="content">
        <div class="field-container">
          <label id="username-label">Username:</label>
          <div class="copy-field">
            <span [attr.aria-labelledby]="'username-label'">{{username}}</span>
            <button mat-icon-button 
                    (click)="copyToClipboard(username)" 
                    matTooltip="Copy username"
                    aria-label="Copy username to clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>
  
        <div class="field-container">
          <label id="password-label">Password:</label>
          <div class="copy-field">
            <span [attr.aria-labelledby]="'password-label'">{{password}}</span>
            <button mat-icon-button 
                    (click)="copyToClipboard(password)" 
                    matTooltip="Copy password"
                    aria-label="Copy password to clipboard">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions align="end">
      <button mat-button (click)="close()">Close</button>
    </mat-dialog-actions>
</div>